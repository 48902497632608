<template>
    <transition name="modal-fade">
        <div class="modal-backdrop" v-on:click="close()">
            <div class="modal" v-on:click.stop=""  role="dialog"  aria-labelledby="modalTitle"  aria-describedby="modalDescription">
                <header class="modal-header justify-content-center align-items-center pt-0 pb-2" id="modalTitle">
                  <h2 v-if="rightAnswers > 3" class="modal-title text-center">Congratulazioni!</h2>
                  <h2 v-if="rightAnswers < 4" class="modal-title text-center">Peccato!</h2>        
                </header>

                <div class="modal-body" id="modalDescription">
                    <div class="row">
                        <div class="col-12">   
                            <div v-if="rightAnswers > 3" class="d-block text-center">
                              <h5>Hai superato brillantemente il test</h5>
                              <p>Ecco il risultato:</p>
                              <p class="my-0"><strong class="fw-bold">Risposte esatte:</strong> {{ rightAnswers }}</p>
                              <p class="mt-0"><strong class="fw-bold">Risposte sbagliate:</strong> {{ 7 - rightAnswers }}</p>
                              <p class="mt-4">Scarica il tuo Green Diploma cliccando sul pulsante in basso:</p>
                              <button v-on:click="requestUser()" :disabled="buttonBlocked" class="orange-cta mx-auto border-0 mt-2">Green Diploma</button>
                            </div>

                            <div v-if="rightAnswers < 4" class="d-block text-center">
                              <p>Non hai superato il test</p>
                              <p>Ecco il risultato:</p>
                              <p class="my-0"><strong class="fw-bold">Risposte esatte:</strong> {{ rightAnswers }}</p>
                              <p class="mt-0"><strong class="fw-bold">Risposte sbagliate:</strong> {{ 7 - rightAnswers }}</p>
                              <p class="mt-4">Non scoraggiarti, hai ancora un’altra possibilità. Ti ricordiamo che per superare il test bisogna rispondere correttamente ad almeno 5 domande.</p>
                              <div class="d-flex justify-content-center align-items-center">
                                <button v-on:click="reload()" class="orange-cta w-50 border-0 mt-2">Prova di nuovo!</button>
                                <router-link class="w-50" to="/capitolo/1/"><button class="grey-cta border-0 mt-2">Rivedi le masterclass</button></router-link>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import axios from "axios"
import download from 'downloadjs'

  export default {
    name: 'ModalQuiz',
    data() {
      return {
        email: '',
        buttonBlocked: false,
      }
    },
    props: ['rightAnswers'],
    mounted() {
      this.sessionKey = this.getCookie()
      this.csrftToken = this.getCSRFT()
    },

    methods: {
      close() {
        this.$emit('close');
      },

      reload() {
        location.reload()
      },

      getCookie() {
        var nameEQ = "sessionCookie" + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
          var c = ca[i];
          while (c.charAt(0)==' ') c = c.substring(1,c.length);
          if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
      },

      getCSRFT() {
        var nameEQ = "csrftoken" + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
          var c = ca[i];
          while (c.charAt(0)==' ') c = c.substring(1,c.length);
          if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
      },

      requestUser() {
        this.buttonBlocked = true
        var fullName = ''
        var email = ''
        var url = process.env.VUE_APP_API_DOMAIN + "api/v1/rest-auth/user/"
        var headers =  {
            'Authorization': 'Token ' + this.sessionKey,
            'Content-Type': 'application/json'
        }
        axios
            .get(url, { headers: headers })
            .then((response) => (
                fullName = response.data.first_name + ' ' + response.data.last_name,
                email = response.data.email,
                this.email = email,
                this.createDiploma(fullName, email)
            ))
            .catch(function(error) {
                console.log(error)
            })
        },

        createDiploma(fullName, email) {
          var url = process.env.VUE_APP_API_DOMAIN + "api/v1/diploma/"
          var headers =  {
              'X-CSRFToken': this.csrftToken,
          }
          var data = {
            'name': fullName,
            'email': email,
          }
          axios
            .post(url, data, { headers: headers, responseType: 'blob'})
            .then((response) => {
              this.close()
              window.open("https://www.versusprojects.com/it?utm_source=GDMS&utm_medium=Down_diploma&utm_campaign=Test_finale&email=" + this.email)
              const content = response.headers['content-type']
              download(response.data, "Green Diploma", content)
            })
            .catch(function(error) {
              console.log(error.response)
            })
        },
      },
  };
</script>

<style lang="sass" scoped>
.modal-backdrop
    position: fixed
    top: 0
    bottom: 0
    left: 0
    right: 0
    background-color: rgba(0, 0, 0, 0.25)
    display: flex
    justify-content: center
    align-items: center
    z-index: 999
  

.modal 
    background: #fff
    overflow-x: auto
    display: flex
    flex-direction: column
    min-width: 700px
    max-width: 50vw
    height: auto
    padding: 50px 40px
    @media (max-width: 700px)
        min-width: 0
        max-width: none

  

.modal-header,
.modal-footer 
    padding: 0
    display: flex
  

.modal-header 
    position: relative
    justify-content: space-between
    padding: 30px
  

.modal-footer 
    border-top: 1px solid #eeeeee
    flex-direction: column
  

.modal-body 
    position: relative  

.btn-close 
    position: absolute
    top: -40px
    right: -20px
    border: none
    font-size: 40px
    padding: 10px
    cursor: pointer
    font-weight: 100
    color: #B17643
    background: transparent

  

.modal-fade-enter,
.modal-fade-leave-to 
    opacity: 0
  

.modal-fade-enter-active,
.modal-fade-leave-active 
    transition: opacity .5s ease

h2, p
  text-align: center !important

button:disabled
  cursor: not-allowed
  pointer-events: all !important

</style>