<template>
    <transition name="modal-fade">
        <div class="modal-backdrop" v-on:click="close()">
            <div class="modal" v-on:click.stop=""  role="dialog"  aria-labelledby="modalTitle"  aria-describedby="modalDescription">
                <header class="modal-header justify-content-center align-items-center pt-0 pb-2" id="modalTitle">
                    <h2 v-if="modalStatus === 'true'" class="modal-title">Esatto!</h2>
                    <h2 v-else class="modal-title">Peccato, la risposta non è corretta!</h2>
                </header>

                <div class="modal-body" id="modalDescription">
                    <div class="row">
                        <div class="col-12">
                            <p>{{ modalContent }}</p>
                            <button :class="modalStatus == 'true' ? 'orange-cta' : 'grey-cta'" class="fw-500 mx-auto mt-5" @click="close">Successivo ></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
  export default {
    name: 'ModalQuiz',
    props: ['modalStatus', 'modalContent'],
    methods: {
      close() {
        this.$emit('close');
      },
    },
  };
</script>

<style lang="sass" scoped>
.modal-backdrop
    position: fixed
    top: 0
    bottom: 0
    left: 0
    right: 0
    background-color: rgba(0, 0, 0, 0.25)
    display: flex
    justify-content: center
    align-items: center
    z-index: 999
  

.modal 
    background: #fff
    overflow-x: auto
    display: flex
    flex-direction: column
    min-width: 700px
    max-width: 50vw
    height: auto
    padding: 50px 40px
    @media (max-width: 700px)
        min-width: 0
        max-width: none

  

.modal-header,
.modal-footer 
    padding: 0
    display: flex
  

.modal-header 
    position: relative
    justify-content: space-between
    padding: 30px
  

.modal-footer 
    border-top: 1px solid #eeeeee
    flex-direction: column
  

.modal-body 
    position: relative  

.btn-close 
    position: absolute
    top: -40px
    right: -20px
    border: none
    font-size: 40px
    padding: 10px
    cursor: pointer
    font-weight: 100
    color: #B17643
    background: transparent

  

.modal-fade-enter,
.modal-fade-leave-to 
    opacity: 0
  

.modal-fade-enter-active,
.modal-fade-leave-active 
    transition: opacity .5s ease

h2, p
  text-align: center !important

</style>