<template>

<section class="about">
	<div class="container-fluid h-vh-100">
		<div class="row h-vh-100">
			<div class="col-lg-6 col-md-6 ps-0 py-0 img-col">
			</div>
			<div class="col-lg-6 col-md-6 content-col d-flex flex-column justify-content-center">
				<h1 class="canela fw-100 orange-text text-size-80">Accedi</h1>
				<form class="form" v-on:submit.prevent=register()>
					<input class="field" required type="email" v-model="email" placeholder="Email">
					<input class="field" required type="password" v-model="password" placeholder="Password">
					<input class="border-0 orange-cta ms-0 me-auto" type="submit" value="Accedi">
					<p v-if="errorList.length > 0">
						<span v-for="error in errorList" v-bind:key="error">{{ error }}</span>
					</p>
					<p class="mt-4">Non hai ancora un account? <router-link class="orange-text" to="/register">Iscriviti</router-link></p>
					<p class="">Hai dimenticato i dati di accesso al tuo account? <span v-on:click="resetPassword()" class="orange-text cursor-pointer">Reimposta la tua password</span></p>
				</form>
			</div>
		</div>
	</div>
</section>
</template>

<script>
import axios from "axios"

export default {
	name: "Login",
	data() {
		return {
			email: '',
			password: '',
			errorList: []
		}
	},
	methods: {
		register() {
			var self = this
			this.errorList = []
			var url = process.env.VUE_APP_API_DOMAIN + "api/v1/rest-auth/login/"
			var data = {
				'email': this.email,
				'password': this.password,
			}
			axios
				.post(url, data)
				.then((response) => (
					this.setCookie("sessionCookie",response.data.key,90),
					this.$router.push("/")
				))
				.catch(function(error) {
					for (var key in error.response.data) {
						self.errorList.push(error.response.data[key][0])
					}
				})
		},
		

		setCookie(name,value,days) {
			var expires = "";
			if (days) {
				var date = new Date();
				date.setTime(date.getTime() + (days*24*60*60*1000));
				expires = "; expires=" + date.toUTCString();
			}
			document.cookie = name + "=" + (value || "")  + expires + "; path=/";
		},

		resetPassword() {
			this.$router.push("/reset")
		}
	}

}
</script>

<style lang="sass" scoped>
	.about
		height: 100vh
		min-height: 100vh
		@media (min-width: 768px)
			.h-vh-100
				min-height: 100vh
		@media (max-width: 768px)
			height: auto
			padding-bottom: 70px
		.img-col
			background-image: url("~@/assets/img/register.jpg")
			background-size: cover
			background-position: center center
			@media (max-width: 768px)
				min-height: 220px
		.content-col
			padding-top: 50px
			padding-bottom: 50px
			padding-left: 100px
			padding-right: 100px
			@media (max-width: 768px)
				padding-right: 22px
				padding-left: 22px
			form
				input.field
					width: 100%
					display: block
					background-color: #f6f6f6
					padding: 18px 15px
					margin-bottom: 20px
					border: none
					border-radius: 0
</style>
