<template>
  <div class="quiz" style="width: 100vw; overflow-x: hidden">
	  <ModalQuiz v-if="modalVisible" :modal-status="isRight" :modal-content="questions[current_question].justification" @close="closeModal"/>
      <ModalFinish v-if="finishQuizModal" :right-answers="right_answers" @close="closeModalFinish" />
	  	<div class="container-fluid flex-grow-1 d-flex" style="overflow-x: hidden">
		  	<div class="row flex-grow-1">
                <div class="col-md-4 d-none px-0 d-md-flex justify-content-center align-items-center">
                    <img src="@/assets/img/quiz/column.jpg" class="w-100 h-100 bg-cover">
                </div>
                <div class="col-md-8 px-0">
                    <div class="row min-h-100">
                        <div class="col-12 position-relative d-flex flex-column justify-content-between text-center">
                            <div class="d-block" style="padding-bottom: 50px">
                                <router-link to="/" class="d-block">
                                    <img class="logo" src="@/assets/img/versus-logo.svg">
                                </router-link>
                                <p class="question-number text-size-24 canela fw-normal fst-italic text-center mt-5">Domande {{ questions.indexOf(questions[current_question]) + 1 }}</p>
                                <h2 class="question-title mx-4 text-center canela fw-100 text-size-56 mt-4 mt-md-0">{{ questions[current_question].question_title }}</h2>
                            </div>
                            <div class="d-block h-auto options position-relative bottom-0 pb-2">
                                <div class="my-2 px-4 col-md-12" v-for="option in questions[current_question].options" v-bind:key="option">							
                                    <button @click="showModal(option.value)" class="option" :class="[option.value, { 'small': option.explanation.length>10}]">{{ option.explanation}}</button>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
		
  </div>
</template>

<script>
import ModalQuiz from '@/components/ModalQuiz.vue';
import ModalFinish from '@/components/ModalFinish.vue';

export default {
    name: 'Quiz',
	components: {
      	ModalQuiz,
        ModalFinish
    },
    data() {
        return {
            sessionKey: null,
			isRight: 'false',
            right_answers: 0,
			modalVisible: false,
            finishQuizModal: false,
            current_question: 0,
            questions: [
                {
                    number: 1,
                    question_title: 'Perché è importante cucinare con prodotti di stagione?',
                    options: [
                        {
                            explanation: 'Per offrire piatti più freschi e di qualità',
                            value: 'false',
                        },
                        {
                            explanation: 'Per sostenere i fornitori locali',
                            value: 'false',
                        },
                        {
                            explanation: 'Per avere prodotti freschi da consumare velocemente, evitando così gli sprechi alimentari',
                            value: 'false',
                        },
                        {
                            explanation: 'Tutte le risposte sono corrette',
                            value: 'true',
                        }
                    ] ,
                    justification: 'Tutte queste sono motivazioni importanti per scegliere di cucinare con prodotti di stagione.'
                },
                {
                    number: 2,
                    question_title: 'Quando si parla di prodotti stagionali ci si riferisce solo a frutta e verdura?',
                    options: [
                        {
                            explanation: 'Sì, frutta e verdura sono gli unici ingredienti che variano a seconda della stagione',
                            value: 'false',
                        },
                        {
                            explanation: 'No, la stagionalità si riferisce anche alla carne',
                            value: 'false',
                        },
                        {
                            explanation: 'No, la stagionalità si riferisce anche alla carne e al pesce',
                            value: 'true',
                        }
                    ] ,
                    justification: "Anche la carne e il pesce sono prodotti stagionali, essendo anch'essi legati all'avvicendarsi delle stagioni."
                },
                {
                    number: 3,
                    question_title: "Qual è l'effetto che si ha nei piatti quando si utilizzano prodotti di stagione?",
                    options: [
                        {
                            explanation: 'I tuoi piatti sono molto colorati',
                            value: 'false',
                        },
                        {
                            explanation: 'I tuoi piatti sono molto gustosi',
                            value: 'true',
                        },
                        {
                            explanation: 'I tuoi piatti sono molto colorati e gustosi',
                            value: 'false',
                        }
                    ] ,
                    justification: "I piatti in cui si utilizzano prodotti di stagione sono molto gustosi perché il prodotto è fresco ed è stato coltivato nel periodo corretto dell’anno. Tuttavia, non sempre i prodotti di stagione sono colorati"
                },
                {
                    number: 4,
                    question_title: 'Perché i fornitori sono così importanti in un progetto sostenibile?',
                    options: [
                        {
                            explanation: 'Perché si prendono cura del primo elemento della cucina: gli ingredienti',
                            value: 'true',
                        },
                        {
                            explanation: 'Perché fanno parte della tua squadra, anche se non in cucina',
                            value: 'true',
                        },
                        {
                            explanation: 'Perché possono offrirti un buon prezzo',
                            value: 'false',
                        },
                        {
                            explanation: 'Perché sono specializzati negli ingredienti che selezionano',
                            value: 'true',
                        }
                    ] ,
                    justification: 'I fornitori sono una parte importante di un progetto sostenibile perché possono fornirti ingredienti locali e di alta qualità.'
                },
                {
                    number: 5,
                    question_title: 'Perché è importante formare il tuo team e condividere le tue conoscenze su cucina e sostenibilità?',
                    options: [
                        {
                            explanation: 'Perché impareranno e daranno nuove idee ed energia per migliorare',
                            value: 'false',
                        },
                        {
                            explanation: 'Perché ti motiveranno come chef',
                            value: 'false',
                        },
                        {
                            explanation: 'Perché permetterà loro di continuare a lavorare secondo criteri sostenibili',
                            value: 'false',
                        },
                        {
                            explanation: 'Tutte le risposte sono corrette',
                            value: 'true',
                        }
                    ] ,
                    justification: "È importante coinvolgere il tuo team nel tuo progetto e condividere con loro le tue conoscenze, poiché consentirà loro di evolversi e continuare a lavorare seguendo pratiche sostenibili."
                },
                {
                    number: 6,
                    question_title: 'Qual è il modo migliore di approcciarsi alla tecnologia secondo chef Oldani?',
                    options: [
                        {
                            explanation: 'Facendo un percorso parallelo e lavorando fianco a fianco',
                            value: 'true',
                        },
                        {
                            explanation: 'Sviluppando la tecnologia e poi provandola al ristorante',
                            value: 'false',
                        },
                        {
                            explanation: 'Implementando tutte le nuove tecnologie nel tuo ristorante per poi decidere quale è la migliore per te',
                            value: 'false',
                        }
                    ] ,
                    justification: 'Chef Oldani ritiene che la tecnologia debba lavorare fianco a fianco con i professionisti, per offrire loro il modo migliore per migliorare le proprie creazioni'
                },
                {
                    number: 7,
                    question_title: "Quali sono gli aspetti più importanti nella scelta di un'attrezzatura sostenibile?",
                    options: [
                        {
                            explanation: 'Potenza e design',
                            value: 'false',
                        },
                        {
                            explanation: 'Design e affidabilità',
                            value: 'false',
                        },
                        {
                            explanation: 'Design, basso consumo energetico e affidabilità',
                            value: 'true',
                        },
                        {
                            explanation: 'Potenza e basso consumo energetico',
                            value: 'false',
                        }
                    ] ,
                    justification: 'Nella scelta delle attrezzature per il proprio ristorante, chef Oldani valuta design, consumi energetici e affidabilità.'
                }                
            ]
        }
    },

    watch: {
        current_question: function() {
            if (this.current_question > 6) {
                this.finishQuizModal = true
            }
        }
    },
    
	methods: {
		nextQuestion() {
			if (this.current_question < 6) {
				this.current_question += 1
			}

            else {
                this.finishQuizModal = true 
            }
		},

		prevQuestion() {
			if (this.current_question > 0) {
				this.current_question -= 1
			}
		},

		showModal(value) {
			this.isRight = value
            if (value == 'true') {
                this.right_answers += 1
            }
			this.modalVisible = true;

		},
		closeModal() {
			this.modalVisible = false;
            if (this.current_question < 6) {
                this.nextQuestion();
            } else {
                 this.finishQuizModal = true
            }
		},

        closeModalFinish() {
            this.finishQuizModal = false
        }
	}
}
</script>

<style scoped>
	html, body {
		color: #000
	}

	.quiz {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		min-height: 100vh;
		width: 100%;
	}

    .logo {
        margin-top: 20px;
        width: 120px
    }

    .question-index {
        list-style: none;
        padding: 0;
    }

    .question-img {
        max-height: 300px;
        object-fit: contain;
        object-position: top;
    }

    .question-index li {
        margin-left: auto;
        margin-right: auto;
        font-weight: 600;
        color: #B17643;
        border: 2px solid #B17643;
        border-radius: 30px;
        width: 30px;
        height: 30px;
        margin-bottom: 10px;
        vertical-align: middle;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .question-index li p {
        font-size: 18px;
        text-align: center;
        margin: 0
    }

    .question-index li.answered {
        background-color: #333;
        color: #fff;
        width: 40px;
        height: 40px
    }

    .question-index li.current {
        width: 50px;
        height: 50px;
        background-color: #333;
        color: #fff
    }

     .question-index li.current p {
        font-size: 21px;
    }

    .row.min-h-100 {
        min-height: 100%;
    }

	.question-number {
		margin-top: 40px;
		font-size: 20px;
		font-weight: 300;
	}

	.options {
		padding-bottom: 30px;
		background-color: #B17643;
        margin: 0;
	}

	.options .row {
		height: 150px;
	}

	.option {
		margin-top: -40px;
		margin-bottom: 40px;
		height: 100%;
		width: 100%;
		font-size: 38px;
        font-family: "Canela";
		font-weight: 300;	
		padding: 25px;
		border:none;
		background: #fff;
		border: 8px solid #fff;
		box-shadow: 0px 5px 10px rgba(0,0,0,.2);
	}

	.option.small {
		font-size: 18px;
	}

	.option.false:focus, .option.false:active {
		background: #333;
		border-color: #fff;
		color: #fff
	}

	.option.true:focus, .option.true:active {
		background: #B17643;
		border-color: #fff;
		color: #fff
	}

    img.bg-cover {
        object-fit: cover;
        object-position: center center;
    }

	button {
		background: #fff;
		border: none;
		padding: 10px;
	}

	button .arrow {
		width: 30px;
	}

	button:first-child .arrow {
		transform: rotate(180deg);
	}

    @media (max-width: 1000px) { 
        .options .row {
            height: 320px
        }

        h2 {
            font-size: 38px
        }

        button:first-child {
            margin-right: 10px;
        }

        img.arrow {
            width: 20px;
        }

        .option {
            font-size: 28px;
        }

        .option.small {
            font-size: 16px
        }
    }


    @media (max-width: 800px) { 
        h2 {
            font-size: 32px;
        }

        .question-img {
            margin-top: 20px;
            width: 100% !important
        }

        .options {
            padding-bottom: 20px;
            background-color: #B17643;
            width: 100vw;
        }

        .options .row {
            height: auto;
        }

        .option {
            margin-top: -20px;
            margin-bottom: 20px;
            font-size: 21px;
            padding: 15px;
        }

        .option.small {
            font-size: 18px;
        }

        button:first-child {
            margin-right: 0;
        }

        .logo {
            margin-top: 20px;
            width: 80px
        }
    }

</style>